import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createOrder } from '../../data/pesapal/api'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography, Paper, Box } from '@mui/material';
import { useFetchSingleUser } from '../../data/users/use-fetch-single-user';


interface CheckoutProps {
  plan: string;
  email: string;
}

const Checkout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { plan, email: stateEmail } = (location.state || {}) as CheckoutProps;
  const { data: user} = useFetchSingleUser();

let email: string | undefined =''
  if (stateEmail == ''){
     email = user?.email
  }else {
     email = stateEmail 
  }


  
  let amount: number;


  if (user?.Role === 'admin') {
    amount = 1;
  } else {
    if (plan === 'Silver') {
      amount = 1299;
    } else if (plan === 'Gold') {
      amount = 1599;
    } else if (plan === 'Platinum') {
      amount = 1999;
    } else if (plan === 'Lawlyfy Chat Tokens' || plan === 'Chat With My Docs Tokens') {
      amount = 260;
    }else {
      amount = 1299;
    }
  }


  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobileNumber: Yup.string()
      .matches(/^(?:\+254|0)[17]\d{8}$/, 'Must be a valid Kenyan phone number')
      .required('Mobile number is required'),
    amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
  });


  const handleCheckout = async (values: any) => {
    setLoading(true);
    setError(null);
    try {
      const order = await createOrder({
        plan,
        amount: values.amount,
        currency: 'KES',
        description: plan,
        billing_address: {
          email_address: email,
          phone_number: values.mobileNumber,
          first_name: values.firstName,
          last_name: values.lastName,
        },
      });

      setRedirectUrl(order.redirect_url);

      //setOrderDetails(order);
    } catch (err) {
      setError('Failed to create order. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="w-full md:w-2/3 mx-auto py-8 md:py-16">
      {error && <Typography color="error">{error}</Typography>}

      {!redirectUrl ? (
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            <Paper className='md:min-h-[80vh] p-4'>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>MPesa Payment Details</Typography>

              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: email,
                  mobileNumber: '',
                  amount: amount,
                }}
                validationSchema={validationSchema}
                onSubmit={handleCheckout}
              >
                {({ errors, touched, isSubmitting, values, handleChange }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} sx={{ paddingBlock: '16px' }}>
                        <TextField
                          fullWidth
                          name="firstName"
                          label="First Name"
                          value={values.firstName}
                          onChange={handleChange}
                          error={touched.firstName && !!errors.firstName}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="lastName"
                          label="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                          error={touched.lastName && !!errors.lastName}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="email"
                          label="Email"
                          value={values.email}
                          InputProps={{ readOnly: true }}
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="mobileNumber"
                          label="Mobile (Mpesa) Number"
                          value={values.mobileNumber}
                          onChange={handleChange}
                          error={touched.mobileNumber && !!errors.mobileNumber}
                          helperText={touched.mobileNumber && errors.mobileNumber}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="amount"
                          label="Amount"
                          type="number"
                          value={values.amount}
                          InputProps={{ readOnly: true }}
                          error={touched.amount && !!errors.amount}
                          helperText={touched.amount && errors.amount}
                        />
                      </Grid>

                      <p className='m-4 text-xs text-gray-500 leading-8'>
                        Please note, you will receive an STK push notification on your registered M-Pesa number shortly. Kindly follow the prompts on your phone to complete the payment.
                      </p>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting || loading}
                          sx={{ paddingBlock: '16px' }}
                          fullWidth
                        >
                          {loading ? 'Processing...' : 'Proceed to Payment'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className='md:min-h-[80vh] p-4'>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {plan} Plan
              </Typography>

              <div className="flex gap-2 text-sm my-4 font-semibold text-gray-700">
                <div className="border-r border-gray-300 pr-2 mr-2">
                  KES {amount}
                </div>

                <div className="border-r border-gray-300 pr-2 mr-2">
                  Unlimited access
                </div>

                <div>
                  Month
                </div>
              </div>

              <div className='rounded-2xl p-4 bg-gray-200 text-sm md:mt-8'>
                <div className='bg-white rounded-2xl mx-4 px-4 py-3 '>
                  Paid monthly
                </div>

                <div className='flex justify-between py-4 px-6 font-semibold text-black border-b border-dotted border-gray-500'>
                  <div>
                    1 user per Month
                  </div>

                  <div>
                    KES {amount}
                  </div>
                </div>

                <div className='my-4 flex justify-between py-4 px-6 font-semibold text-black border-b border-dotted border-gray-500'>
                  <div className='flex flex-col'>
                    <div className='font-semibold'>
                      Updates
                    </div>
                    <div className='mt-3 font-light'>
                      Frequent updates for all current services
                    </div>
                  </div>
                </div>

                <div className='my-4 py-4 px-6 text-black '>
                  <div>
                    <div className='font-semibold'>
                      Services Offered
                    </div>

                    <ul className='font-light my-4 space-y-3'>
                      {/* Lawlify Chat Tokens: Only show Wakili Chat */}
                      {plan === 'Lawlyfy Chat Tokens' && (
                        <li>*<span className='font-semibold'> Wakili Chat :</span> 200 Chats + Citations</li>
                      )}

                      {/* Chat With My Docs Tokens: Only show Chat With My Docs */}
                      {plan === 'Chat With My Docs Tokens' && (
                        <li>*<span className='font-semibold'> Chat With My Docs :</span> 100 Chats + Citations</li>
                      )}

                      {/* All other plans: Show full list */}
                      {plan !== 'Lawlyfy Chat Tokens' && plan !== 'Chat With My Docs Tokens' && (
                        <>
                          <li>*<span className='font-semibold'> Semantic AI :</span> Up to 500 Queries</li>
                          <li>*<span className='font-semibold'> Keyword Search :</span> Unlimited Searches</li>
                          {(plan === 'Silver' || plan === 'Platinum') && (
                            <li>*<span className='font-semibold'> Wakili Chat :</span> 200 Chats + Citations</li>
                          )}
                          {(plan === 'Gold' || plan === 'Platinum') && (
                            <li>*<span className='font-semibold'> Chat With My Docs :</span> 100 Chats + Citations</li>
                          )}
                        </>
                      )}
                    </ul>


                  </div>
                </div>

              </div>

            </Paper>
          </Grid>
        </Grid>
      ) : (
        <div>
          <iframe src={redirectUrl} width="100%" height="600px" title="Payment" />
        </div>
      )}
    </div>
  );
};
export default Checkout;
