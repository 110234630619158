import React from 'react';
import KenyaLaw from "../../img/Landing_KenyaLaw.png";
import Cloud from "../../img/Cloud.png";
import Wand from "../../img/Wand.png";

export default function DocRepo() {
  return (
    <section id="kenyalawreports" className="py-6 md:py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-stretch md:space-x-8">
          {/* Text and Features Container */}
          <div className="flex-1 md:w-1/2 flex flex-col justify-between shadow-xs px-1 md:px-6">
            <div>
              <h3 className="text-center md:text-left text-xl lg:text-4xl font-medium text-gray-900">
                Kenya Law Reports
              </h3>
              {/* Mobile Image - only visible on mobile */}
              <div className="md:hidden mt-4 mb-6">
                <img
                  src={KenyaLaw}
                  alt="Kenya Law Reports"
                  className="w-full h-auto object-contain rounded-xl"
                />
              </div>
              <p className="mt-3 text-[12px] text-gray-800 leading-loose">
                Lawlyfy integrates the best of AI to deliver a superior search experience across Kenya's <span className="font-semibold">case law, the Kenya gazette, Laws of Kenya, and the Cause List.</span> Unlike traditional keyword-based legal databases, Semantic AI offers a truly intuitive search experience that takes into account the context and nuances of your legal queries.
              </p>
            </div>

            {/* Features Section */}
            <div className="flex flex-col sm:flex-row justify-between gap-6 mt-3">
              {/* Feature 1 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Cloud} alt="No Downtime" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto " />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  No Downtime
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  We have dedicated the necessary resources to ensure you can access any document you need, when you need it.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Wand} alt="Semantic AI" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto " />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  Semantic AI
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  Describe your research needs in general terms and our AI assistant will provide relevant, contextual results.
                </p>
              </div>
            </div>
          </div>

          {/* Image Container - Hidden on mobile, visible on larger screens */}
          <div className="hidden md:flex flex-1 md:w-1/2 justify-center items-stretch">
            <img
              src={KenyaLaw}
              alt="Kenya Law Reports"
              className="w-full h-full object-contain rounded-2xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}