import { useFetchUsers } from "../../../../data/users/use-fetch-users";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faKey, faUser, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const Admin: React.FC = () => {
  const navigate = useNavigate();
  const { data: users, isLoading, error } = useFetchUsers();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const usersPerPage = 6;

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users?.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = users ? Math.ceil(users.length / usersPerPage) : 1;

  const handleNextPage = () => {
    setCurrentPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const toggleMenu = (userId: string) => {
    setActiveMenu(activeMenu === userId ? null : userId);
  };

  // Function to convert JSON data to CSV
  const exportToCSV = () => {
    if (!users || users.length === 0) return;

    const csvRows: string[] = [];

    // Define headers for CSV
    const headers = [
    'Name',  'Email', 'Subscription Status', 'Joined', 'Last Used', 'WC', 'CWMD', 
      'WC SA', 'CWMD SA', 'Sem', 'Key'
    ];
    csvRows.push(headers.join(','));

    // Add user data to CSV
    users.forEach(user => {
      const row = [
        user.name,
        user.email,
        user.subscriptionStatus,
        `"${new Date(user.joined).toLocaleDateString('en-US')}"`, // Format date and enclose in quotes
        `"${user.lastUsed ? new Date(user.lastUsed).toLocaleDateString('en-US') : 'N/A'}"`, 
        user.nonSpecificChatMessages,
        user.specificChatMessages,
        user.nonSpecificChatMessagesSinceActivation,
        user.specificChatMessagesSinceActivation,
        user.semanticCount,
        user.keywordCount
      ];
      csvRows.push(row.join(','));
    });

    // Create CSV file
    const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users_data.csv");
    document.body.appendChild(link);

    link.click();
  };

  return (
    <div className=" bg-slate-50 overflow-scroll text-[11px] py-5">
      <div className="container mx-auto px-4">
        <div className="flex justify-end mb-4">
          {/* Export Button */}
          <button onClick={exportToCSV} className="bg-green-500 text-white py-2 px-4 rounded">
            Export as CSV
          </button>
        </div>

        <div className="overflow-x-scroll">
          <table className="min-w-full bg-white leading-8 table-auto">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-3 px-4 border-b whitespace-nowrap">Email</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">Subscription Status</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">Joined</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">Last Used</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">WC</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">CWMD</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">WC SA</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">CWMD SA</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">Sem</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">Key</th>
                <th className="py-3 px-4 border-b whitespace-nowrap">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => (
                <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="py-2 px-4 border-b">{user.email}</td>
                  <td className="py-2 px-4 border-b">{user.subscriptionStatus}</td>
                  <td className="py-2 px-4 border-b">{new Date(user.joined).toLocaleDateString()}</td>
                  <td className="py-2 px-4 border-b">{user.lastUsed ? new Date(user.lastUsed).toLocaleDateString() : 'N/A'}</td>
                  <td className="py-2 px-4 border-b">{user.nonSpecificChatMessages}</td>
                  <td className="py-2 px-4 border-b">{user.specificChatMessages}</td>
                  <td className="py-2 px-4 border-b">{user.nonSpecificChatMessagesSinceActivation}</td>
                  <td className="py-2 px-4 border-b">{user.specificChatMessagesSinceActivation}</td>
                  <td className="py-2 px-4 border-b">{user.semanticCount}</td>
                  <td className="py-2 px-4 border-b">{user.keywordCount}</td>
                  <td className="py-2 px-4 border-b relative">
                    <button onClick={() => toggleMenu(user.id)} className="text-gray-600 hover:text-gray-800">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    {activeMenu === user.id && (
                      <div className="absolute right-0 mt-2 w-64 text-gray-900 bg-white rounded-md shadow-lg z-10">
                        <button onClick={() => navigate(`/wakili/admin/users/${user.id}`)} className="block px-4 text-xs text-gray-700 hover:bg-gray-100 w-full text-left">
                          <FontAwesomeIcon icon={faUser} className="inline mr-2"/> View Profile
                        </button>
                        <button onClick={() => navigate(`/wakili/admin/users/${user.id}/permissions`)} className="block px-4  text-xs text-gray-700 hover:bg-gray-100 w-full text-left">
                          <FontAwesomeIcon icon={faKey} className="inline mr-2"/> Change Permissions
                        </button>
                        <button onClick={() => {/* Handle delete */}} className="block px-4  text-xs text-gray-700 hover:bg-gray-100 w-full text-left">
                          <FontAwesomeIcon icon={faTrash} className="inline mr-2"/>Delete User
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between mt-6">
          <button
            className="bg-blue-500 text-white py-3 px-4 rounded disabled:bg-blue-300"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="bg-blue-500 text-white py-3 px-4 rounded disabled:bg-blue-300"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
